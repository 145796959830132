'use strict';function updateCountdown(element) {
  var endDateString = element.getAttribute('data-enddate');

  //Pull values out of raw string so it can be converted to useable UTC date
  var yearString = parseInt(endDateString.substr(0, 4), 10);
  var monthString = parseInt(endDateString.substr(4, 2), 10) - 1;
  var dayString = parseInt(endDateString.substr(6, 2), 10);
  var hourString = parseInt(endDateString.substr(9, 2), 10);
  var minuteString = parseInt(endDateString.substr(11, 2), 10);
  var secondString = parseInt(endDateString.substr(13, 2), 10);
  var endDate = new Date(Date.UTC(yearString, monthString, dayString, hourString, minuteString, secondString));

  var daysSpan = element.querySelector('.js-cdt-days');
  var hoursSpan = element.querySelector('.js-cdt-hours');
  var minutesSpan = element.querySelector('.js-cdt-minutes');
  var secondsSpan = element.querySelector('.js-cdt-seconds');

  var intervalId = setInterval(function () {
    var now = new Date().getTime();
    var timeLeft = endDate - now;

    if (timeLeft <= 0) {
      [daysSpan, hoursSpan, minutesSpan, secondsSpan].forEach(function (el) {
        if (el) el.innerHTML = '0';
      });
      clearInterval(intervalId);
      return;
    }

    var days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    var hours = Math.floor(timeLeft % (1000 * 60 * 60 * 24) / (1000 * 60 * 60));
    var minutes = Math.floor(timeLeft % (1000 * 60 * 60) / (1000 * 60));
    var seconds = Math.floor(timeLeft % (1000 * 60) / 1000);

    [daysSpan, hoursSpan, minutesSpan, secondsSpan].forEach(function (el, index) {
      if (el) el.innerHTML = [days, hours, minutes, seconds][index];
    });
  }, 1000);
}

var countdownElements = document.querySelectorAll('.js-countdown');

countdownElements.forEach(updateCountdown);